<template>
    <v-main>
        <v-container>
            <div class="text-h4 mb-4">Workspace Admin</div>
            <!-- <div v-if="loading">
                <v-skeleton-loader class="mb-4" type="paragraph" v-for="n in 10" :key="n" />
            </div> -->
            <v-row class="d-flex align-center">
                <v-col cols="10">
                    <SearchPeople
                        ref="sPeople"
                        v-model="foundUser"
                        label="Search people"
                        :show-loader="false"
                        users-only
                        ldap-populate
                        dense
                        rounded
                        outlined
                    />
                </v-col>
                <v-col class="p-0 m-0">
                    <v-btn
                        @click="search"
                        icon
                        small
                    >
                        <v-icon>mdi-magnify</v-icon>
                    </v-btn>
                    
                </v-col>
            </v-row>
            <div v-if="loading">
                <v-row class="fill-height text-center" align-content="center" justify="center" style="height: 200px">
                    <v-col cols="8">
                        <div class="text-h6 font-weight-light">Loading...</div>
                        <v-progress-linear
                            indeterminate
                            rounded
                            height="3"
                        ></v-progress-linear>
                    </v-col>
                </v-row>
            </div>
            <div class="mt-4" v-else-if="Object.keys(workspaces).length > 0">
                <v-row>
                    <v-card
                        elevation="2"
                        outlined
                        width="350"
                        class="mx-2 my-2 mx-auto"
                        v-for="curWorkspace, id of workspaces"
                        :key="id"
                        :loading="workspaceLoading.includes(curWorkspace.id)"
                    >
                        <v-card-title>
                            {{ getWorkspaceName(curWorkspace) }}
                            <v-icon small class="ml-2" :color="getStatusColor(curWorkspace)">mdi-checkbox-blank-circle</v-icon>
                            <v-row align="center" justify="end">
                                <v-btn @click="refreshWorkspace(curWorkspace)" icon class="ml-2 ">
                                    <v-icon small>mdi-refresh</v-icon>
                                </v-btn>
                                <v-menu
                                    bottom
                                    right
                                    v-if="curWorkspace.client_data_address || curWorkspace.IpAddress"
                                >
                                    <template v-slot:activator="{on, attrs}">
                                        <v-btn
                                            icon
                                            v-bind="attrs"
                                            v-on="on"
                                            class="mr-2"
                                        >
                                        <v-icon small>mdi-dots-vertical</v-icon>
                                        </v-btn>
                                    </template>
                                    <v-list
                                        dense
                                    >
                                        <v-list-item @click="rebootWorkspace(curWorkspace)">
                                            <v-list-item-title>Reboot Workspace</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item @click="restoreWorkspace(curWorkspace)">
                                            <v-list-item-title>Restore Workspace</v-list-item-title>
                                        </v-list-item>
                                        <!-- <v-list-item v-if="curWorkspace.session_state" @click="rebootMachine(curWorkspace)">
                                            <v-list-item-title>Reboot Machine</v-list-item-title>
                                        </v-list-item> -->
                                    </v-list>
                                </v-menu>
                            </v-row>
                            
                        </v-card-title>
                        <v-card-text>
                            <div>User: {{ curWorkspace.UserName }}</div>
                            <div v-if="curWorkspace.desktop_pool_or_farm_name">Pool: {{ curWorkspace.desktop_pool_or_farm_name }}</div>
                            <div v-if="curWorkspace.session_state">Status: {{ curWorkspace.session_state }}</div>
                            <div v-else>Status: {{ curWorkspace.State }} </div>
                            <div v-if="curWorkspace.IpAddress">IP: {{ curWorkspace.IpAddress }}</div>
                            <div v-else-if="curWorkspace.client_data_address">IP: {{ curWorkspace.client_data_address }}</div>
                            <div v-if="curWorkspace.WorkspaceProperties">Compute Type: {{ curWorkspace.WorkspaceProperties.ComputeTypeName }}</div>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer />
                            <v-spacer />
                            <v-btn @click="removeWorkspace(curWorkspace)" icon><v-icon small>mdi-close</v-icon></v-btn>
                        </v-card-actions>
                    </v-card>
                </v-row>
            </div>
        </v-container>
        <ConfirmDialog
            ref="confirmDlg"
            :title="confirmDlgText"
            confirm-button-text="Confirm"
            confirm-button-icon="mdi-check"
            @confirm="confirmAction()"
        />
    </v-main>
</template>

<script>
import SearchPeople from '@/components/population/SearchPeople';
import ConfirmDialog from '@/components/common/ConfirmDialog';
import { computed, onBeforeUnmount, ref, watch } from '@vue/composition-api';

export default {
    components: {
        SearchPeople,
        ConfirmDialog
    },
    setup(props, { root }) {
        
        const foundUser = ref(null);

        const userList = ref([]);

        const store = root.$store;
        
        const loading = ref(false);
        
        const sPeople = ref(null);

        const confirmDlgText = ref('');
        const confirmDlg = ref(null);

        const workspaceAction = ref({
            workspace: null,
            action: '',
            instance: ''
        });

        const workspaceLoading = ref([]);

        const rebootWorkspace = (workspace) => {
            confirmDlgText.value = "Are you sure you want to reboot this workspace?";
            confirmDlg.value.show();
            workspaceAction.value.action = 'reboot';
            workspaceAction.value.workspace = workspace;
        }

        const restoreWorkspace = (workspace) => {
            confirmDlgText.value = "Are you sure you want to restore this workspace?";
            confirmDlg.value.show();
            workspaceAction.value.action = 'restore';
            workspaceAction.value.workspace = workspace;
            // if (workspace.session_state) {
            //     workspaceAction.value.instance = 'horizon'
            // } else {
            //     workspaceAction.value.instance = 'workspace'
            // }
        }

        const rebootMachine = (workspace) => {
            confirmDlgText.value = "Are you sure you want to reboot this machine?";
            confirmDlg.value.show();
            workspaceAction.value.action = 'rebootMachine';
            workspaceAction.value.workspace = workspace;
        }

        const removeWorkspace = (curWorkspace) => {
            // store.dispatch('workspaceAdmin/removeWorkspaceByUni', curWorkspace.UserName);
            store.dispatch('workspaceAdmin/removeWorkspaceById', curWorkspace);
        }

        const confirmAction = async () => {
            let action = workspaceAction.value.action;
            let workspace = workspaceAction.value.workspace;
            
            if (action == 'restore') {
                await store.dispatch('workspaceAdmin/restoreWorkspaceById', workspace.WorkspaceId);
            } else if (action == 'rebootMachine') {
                await store.dispatch('workspaceAdmin/rebootMachineById', workspace);
            }else {
                await store.dispatch('workspaceAdmin/rebootWorkspaceById', workspace.WorkspaceId);
            }

            resetConfirmDlg();
        }

        const refreshWorkspace = async (workspace) => {
            let user = workspace.UserName;
            workspaceLoading.value.push(workspace.id);
            await store.dispatch('workspaceAdmin/getSessionsByUni', user)
            // await store.dispatch('workspaceAdmin/getWorkspaceByUni', user);
            workspaceLoading.value = workspaceLoading.value.filter((item) => item !== workspace.id);
        }

        const resetConfirmDlg = () => {
            workspaceAction.value = {
                action: '',
                workspace: null
            }
        }
        // const curWorkspace = computed (() => store.getters['workspaceAdmin/getCurWorkspace']);
        const workspaces = computed(() => store.getters['workspaceAdmin/getWorkspaces']);
        
        watch(foundUser, async (val) => {
            if (!val) return;
            loading.value = true;
            // await store.dispatch('workspaceAdmin/getWorkspaceByUni', val.cu_uni)
            await search();
            loading.value = false;
        });
        
        const search = async () => {
            loading.value = true;
            await store.dispatch('workspaceAdmin/getSessionsByUni', foundUser.value.cu_uni)
            userList.value.push(foundUser.value)
            sPeople.value.clear();
            loading.value = false;
        };

        const getStatusColor = (workspace) => {
            // let status = curWorkspace.State;
            let status;
            if (workspace.session_state) {
                status = workspace.session_state;
            } else {
                status = workspace.State;
            }

            status = status.toUpperCase();

            // console.log(status);
            let color = '';
            switch (status) {
                case 'AVAILABLE':
                case 'CONNECTED':
                    color = 'green'
                    break;
                case 'STOPPED':
                case 'ERROR':
                case 'TERMINATED':
                case 'UNHEALTHY':
                case 'PROTOCOL_FAILURE':
                case 'AGENT_UNREACHABLE':	
                    color = 'red';
                    break;
                default: 
                    color = 'yellow';
                    break;
            }

            return color;
        };

        const getWorkspaceName = (workspace) => {
            if (workspace.ComputerName) return workspace.ComputerName;
            
            let name;
            if (workspace.machine_or_rds_server_dns_name) {
                name = workspace.machine_or_rds_server_dns_name
            } else if (workspace.dns_name) {
                name = workspace.dns_name
            }
            name = name.replace('.mc.cumc.columbia.edu', '');

            return name;
            
        };

        onBeforeUnmount(() => {
            store.dispatch('workspaceAdmin/reset');
        })


        return {
            loading,
            foundUser,
            // curWorkspace,
            getStatusColor,
            sPeople,
            workspaces,
            rebootWorkspace,
            restoreWorkspace,
            confirmDlgText,
            confirmDlg,
            confirmAction,
            refreshWorkspace,
            workspaceLoading,
            removeWorkspace,
            getWorkspaceName,
            search,
            rebootMachine
        }
    },
};
</script>

<style scoped>
.v-list-item-title {
    font-size: 8px;
}
</style>